import config from '@/config/env-constants';
import moment from 'moment';
import _ from 'lodash';

function cleanupFields(account) {
	let cleanedObj = { ...account };

	// Remove exist details
	delete cleanedObj['Asset Types'];
	delete cleanedObj['Reason for Update'];
	delete cleanedObj['Created By'];
	delete cleanedObj['Updated By'];
	delete cleanedObj['Start Date'];
	delete cleanedObj['Date Created'];
	delete cleanedObj['Date Updated'];
	delete cleanedObj['_showDetails'];

	return cleanedObj;

}

function getAssetTypeDisplay(clientAccount) {
	let assetTypes = "";

	_.forEach(clientAccount.assetTypes, item => {
		assetTypes += (assetTypes.length > 0 ? ';' : '') + `${item.assetType} - ${item.rentRate} / ${item.rentFrequency}`;
	});

	return assetTypes;
}

function getAssetTypeEntry(assetTypeObj) {
	return {
		assetTypeId: assetTypeObj.id,
		assetType: assetTypeObj.name,
		rentFrequencies: config.rentFrequencyTypes,
		conditions: [{ value: null, text: ' - Please select - ' }],
		condition: null
	}
}

function getContractPeriodNoOfMonths(contractPeriod) {
	const contractDetails = contractPeriod.split(' ');
	const contractLength = parseInt(contractDetails[0]);
	const contractMetric = contractDetails[1];
	return contractMetric.includes('Year') ? contractLength * 12 : contractLength;
}

function getContractEndingDate(timestamp, contractPeriod) {
	const noOfMonths = getContractPeriodNoOfMonths(contractPeriod);
	const originalDate = new Date(timestamp);
	const newDate = new Date(originalDate);
	newDate.setMonth(newDate.getMonth() + noOfMonths);
	return newDate.getTime();
}

function getTotalBillings(contractPeriod, frequency) {
	let totalBillings = 0;

	const contractDetails = contractPeriod.split(' ');
	const contractLength = parseInt(contractDetails[0]);
	const metric = contractDetails[1];

	if (metric.includes('Year')) {
		if (frequency === 'Yearly') {
			totalBillings = contractLength;
		} else if (frequency === 'Monthly') {
			totalBillings = contractLength * 12;
		} else if (frequency === 'Daily') {
			totalBillings = contractLength * 365;
		}
	} else if (metric.includes('Month')) {
		if (frequency === 'Monthly') {
			totalBillings = contractLength;
		} else if (frequency === 'Daily') {
			totalBillings = contractLength * 365;
		}
	}

	return totalBillings;
}

function getDaysInMonth(month) {
	const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

	const months = [
		"January", "February", "March", "April",
		"May", "June", "July", "August",
		"September", "October", "November", "December"
	];

	const monthIndex = months.indexOf(month);
	if (monthIndex !== -1) {
		return daysInMonth[monthIndex];
	}

	return daysInMonth[0];
}

function getBillingFrequency(frequency, schedule) {
	switch (frequency) {
		case 'Daily': return `${frequency}, ${moment(schedule, "HH:mm:ss").format("hh:mm A")} of the Day`;
		case 'Monthly': return `${frequency}, ${moment().set('date', schedule).format('Do [of the month]')}`;
		case 'Yearly': return `${frequency}, ${moment(schedule, 'MMMM D').format('MMMM Do [of the year]')}`;
	}
	return '-';
}

function getClientAccountDefaultObj() {
	return {
		assetOwnerId: '',
		assetOwner: '',
		clientId: '',
		client: '',
		principalId: '',
		principal: '',
		accountNo: '',
		accountManagerId: '',
		accountManager: '',
		clientManagerId: '',
		clientManager: '',
		remarks: '',

		contractPeriod: null,
		startDate: 0,
		endingDate: 0,
		billingFrequency: 'Monthly',
		billingSchedule: '',
		paymentTerms: '',
		totalBillings: 0,

		assetTypes: [],
		status: 'Inactive',
		updateReason: '',
		activateReason: '',
		terminationReason: '',

		dateCreated: 0,
		createdBy: '',
		dateUpdated: 0,
		updatedBy: '',
		dateActivated: 0,
		activatedBy: '',
		dateTerminated: 0,
		terminatedBy: ''
	}
}

function hasAssetType(account, assetTypeId) {
	let assetTypes = account.assetTypes ? account.assetTypes : {};
	if (!_.isEmpty(assetTypes)) {
		let assetTypeIds = Object.keys(assetTypes);
		return assetTypeIds.includes(assetTypeId);
	}
	return false;
}

function hasActiveClientAccount(clientAccounts, sourceId, destinationId) {
	let filteredAccountsObj = _.filter(clientAccounts, o => {
		return o.status === 'Active' && (((sourceId.length === 0 || sourceId === o.assetOwnerId) && (destinationId.length === 0 || destinationId === o.clientId))
			|| ((sourceId.length === 0 || sourceId === o.clientId) && (destinationId.length === 0 || destinationId === o.assetOwnerId)));
	});

	return !_.isEmpty(filteredAccountsObj);
}

function getClientAccounts(clientAccounts, sourceId, destinationId) {
	let filteredAccountsObj = _.filter(clientAccounts, o => {
		return o.status === 'Active' && (((sourceId.length === 0 || sourceId === o.assetOwnerId) && (destinationId.length === 0 || destinationId === o.clientId))
			|| ((sourceId.length === 0 || sourceId === o.clientId) && (destinationId.length === 0 || destinationId === o.assetOwnerId)));
	});

	return _.keyBy(filteredAccountsObj, 'accountNo');
}

function getAccountsOfAssetUser(clientAccounts, sourceId) {
	let filteredAccountsObj = _.filter(clientAccounts, o => {
		return o.status === 'Active' && sourceId === o.clientId;
	});

	return _.keyBy(filteredAccountsObj, 'accountNo');
}

function getContractedQuantity(clientAccount, currAssetTypeId) {
	let contractedQuantity = 0;

	if (!_.isEmpty(clientAccount) && currAssetTypeId) {
		let assetTypes = Object.values(clientAccount.assetTypes);

		for (const assetType of assetTypes) {
			if (assetType.assetTypeId === currAssetTypeId) {
				contractedQuantity = assetType.quantity;
				break;
			}
		}
	}

	return contractedQuantity;
}


export const ClientAccountUtil = {
	cleanupFields,
	getAssetTypeDisplay,
	getAssetTypeEntry,
	getContractEndingDate,
	getTotalBillings,
	getDaysInMonth,
	getBillingFrequency,
	getClientAccountDefaultObj,
	hasAssetType,
	hasActiveClientAccount,
	getClientAccounts,
	getAccountsOfAssetUser,
	getContractedQuantity
}
