<template>
	<b-modal id="edit-client-account" size="lg" title="Edit Client Account" ref="modal" ok-title="Save" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs>
						<b-tab title="Primary Information" active>
							<b-row class="my-2">
								<b-col sm="8">
									<b>PRIMARY INFORMATION</b>
								</b-col>
							</b-row>
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Asset Owner" label-for="Asset Owner">
										<b-form-input name="Asset Owner" type="text" v-model="form.assetOwner"
											readonly />
									</b-form-group>
								</b-col>
								<b-col lg="6" md="6" sm="=12">
									<b-form-group label="Account No" label-for="Account No"
										description="Allowed input: letters, numbers, ( ) . + - and space">
										<b-form-input id="accountNo" name="Account No" type="text" class="numFont"
											v-model="form.accountNo"
											v-validate="getValidationParam(true, accountNoRegex)"
											placeholder="Account No" maxlength="100"
											:disabled="form.status === 'Active' && !isSuperAdmin ? 'disabled' : false" />
										<span v-show="errors.has('Account No')" class="help-block">
											{{ errors.first('Account No') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Client" label-for="Client">
										<b-form-input name="Client" type="text" v-model="form.client" readonly />
									</b-form-group>
								</b-col>

								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Principal" label-for="Principal">
										<b-form-input name="Principal" type="text" v-model="form.principal" readonly />
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="mt-4 mb-2">
								<b-col sm="8">
									<b>PERSONS-IN-CHARGE</b>
								</b-col>
							</b-row>
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Account Manager" label-for="Account Manager"
										description="Asset Owner's Sales Representative">
										<v-select id="accountManager" name="Account Manager" class="style-chooser"
											label="text" placeholder=" - Please select - " :options="accountManagerOptions"
											:reduce="(manager) => manager.value" v-model="selAccountManager"
											v-validate="'selectRequired'">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a client
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Account Manager')" class="help-block">
											{{ errors.first('Account Manager') }}
										</span>
									</b-form-group>
								</b-col>

								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Client Manager" label-for="Client Manager"
										description="Client's Representative">
										<v-select id="clientManager" name="Client Manager" class="style-chooser"
											label="text" placeholder=" - Please select - " :options="clientManagerOptions"
											:reduce="(manager) => manager.value" v-model="selClientManager"
											v-validate="'selectRequired'">

											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a principal
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Client Manager')" class="help-block">
											{{ errors.first('Client Manager') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="mt-4 mb-2">
								<b-col sm="8">
									<b>OTHER DETAILS</b>
								</b-col>
							</b-row>
							<b-row class="my-2">
								<b-col lg="6" md="6" sm="12">
									<b-form-group label="Remarks" label-for="Remarks" description>
										<b-form-textarea name="Remarks" type="text" v-model="form.remarks"
											maxlength="200" v-validate="getValidationParam(true, remarksRegex)"
											:rows="3" placeholder="Remarks" />
										<span v-show="errors.has('Remarks')" class="help-block">
											{{ errors.first('Remarks') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

						</b-tab>

						<b-tab title="Billing Details">
							<b-row class="my-2">
								<b-col sm="8">
									<b>BILLING DETAILS</b>
								</b-col>
							</b-row>

							<!-- Contract Period -->
							<b-row class="my-1" no-gutters>
								<b-col lg="5" md="12" sm="12" class="mr-3">
									<b-form-group label="Contract Period" label-for="Contract Period">
										<b-form-select id="contractPeriod" name="Contract Period"
											v-model="selContractPeriod" v-validate="'required'"
											:options="contractPeriodOptions" :reduce="(period) => period.value" />
										<span v-show="errors.has('Contract Period')" class="help-block">
											{{ errors.first('Contract Period') }}
										</span>
									</b-form-group>
								</b-col>

								<!-- Others -->
								<b-col v-if="selContractPeriod === ' - Others - '" lg="3" md="5" sm="5" class="mr-1">
									<b-form-group label="If Others, please specify" description="Length">
										<b-form-input id="contract-length" name="Length" type="number" class="numFont"
											v-model="selContractLength"
											v-validate="'required|min_value:1|max_value:12'" />
										<span v-show="errors.has('Length')" class="help-block">
											{{ errors.first('Length') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col v-if="selContractPeriod === ' - Others - '" lg="3" md="5" sm="5">
									<b-form-group class="field-without-label" description="Metric">
										<b-form-select id="contract-metric" name="Metric" v-model="selContractMetric"
											v-validate="{ required: isOthersContractPeriodRequired }"
											:options="contactMetricOptions" :reduce="(period) => period.value" />
										<span v-show="errors.has('Metric')" class="help-block">
											{{ errors.first('Metric') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

							<!-- Billing Frequency -->
							<b-row class="my-1" no-gutters>
								<b-col lg="5" md="12" sm="12" class="mr-3">
									<b-form-group label="Billing Frequency" label-for="Billing Frequency">
										<b-form-select id="billingFrequency" name="Billing Frequency"
											v-model="form.billingFrequency" v-validate="'required'"
											:options="billingFrequencyOptions" />
										<span v-show="errors.has('Billing Frequency')" class="help-block">
											{{ errors.first('Billing Frequency') }}
										</span>
									</b-form-group>
								</b-col>

								<!-- Daily -->
								<b-col v-if="form.billingFrequency === 'Daily'" lg="5" md="10" sm="10">
									<b-form-group label="Time of the Day">
										<b-form-timepicker name="Time" v-model="selBillTimeSchedule" locale="en"
											v-validate="{ required: isTimeOfDayRequired }" />
										<span v-show="errors.has('Time')" class="help-block">
											{{ errors.first('Time') }}
										</span>
									</b-form-group>
								</b-col>

								<!-- Monthly -->
								<b-col v-if="form.billingFrequency === 'Monthly'" lg="5" md="10" sm="10">
									<b-form-group label="Day of the Month">
										<b-form-select id="billing-day" name="Day" v-model="selBillDaySchedule"
											v-validate="{ required: isDayOfMonthRequired }" :options="dailyOptions"
											:reduce="(day) => day.value" />
										<span v-show="errors.has('Day')" class="help-block">
											{{ errors.first('Day') }}
										</span>
									</b-form-group>
								</b-col>

								<!-- Yearly -->
								<b-col v-if="form.billingFrequency === 'Yearly'" lg="3" md="5" sm="5" class="mr-1">
									<b-form-group label="Day of the Year" description="Month">
										<b-form-select id="billing-month" name="Month" v-model="selBillMonthSchedule"
											v-validate="{ required: isDayOfYearRequired }" :options="monthOptions"
											:reduce="(month) => month.value" />
										<span v-show="errors.has('Month')" class="help-block">
											{{ errors.first('Month') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col v-if="form.billingFrequency === 'Yearly'" lg="3" md="5" sm="5">
									<b-form-group class="field-without-label" description="Day">
										<b-form-select id="billing-day" name="Day" v-model="selBillDaySchedule"
											v-validate="{ required: isDayOfYearRequired }" :options="dailyOptions"
											:reduce="(day) => day.value" />
										<span v-show="errors.has('Day')" class="help-block">
											{{ errors.first('Day') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="my-1" no-gutters>
								<b-col lg="5" md="12" sm="12" class="mr-3">
									<b-form-group label="Contract Start Date" label-for="Contract Start Date"
										description>
										<b-form-datepicker name="Contract Start Date" v-model="selStartDate" locale="en"
											reset-button label-reset-button="Clear"
											:date-format-options="dateFormatOptions" v-validate="'required'"
											:disabled="form.status === 'Active' && !isSuperAdmin ? 'disabled' : false" />
										<span v-show="errors.has('Contract Start Date')" class="help-block">
											{{ errors.first('Contract Start Date') }}
										</span>
									</b-form-group>
								</b-col>
								<b-col lg="5" md="12" sm="12">
									<b-form-group label="Payment Terms" label-for="Payment Terms"
										description="Days after billing period when payment is due">
										<b-form-input id="payment-terms" name="Payment Terms" type="number"
											class="numFont" step="1" v-model="form.paymentTerms"
											v-validate="'required|min_value:1'" :min="1" placeholder="0"
											@keydown="blockInvalidCharacters" />
										<span v-show="errors.has('Payment Terms')" class="help-block">
											{{ errors.first('Payment Terms') }}
										</span>
									</b-form-group>
								</b-col>
							</b-row>

						</b-tab>

						<b-tab title="Asset Types">
							<b-row class="my-2">
								<b-col sm="8">
									<b>LIST OF ASSET TYPES</b>
								</b-col>
							</b-row>
							<b-row class="my-4" no-gutters>
								<b-col lg="6" md="6" sm="12" class="mr-2">
									<b-form-group description="Select the asset types to rent by the client">
										<v-select id="assetType" name="Select Asset Type" class="style-chooser"
											label="text" placeholder=" - Please select - " v-model="selAssetType" :options="assetTypeOptions"
											:reduce="(assetType) => assetType.value">

											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a asset type
												</em>
											</template>
										</v-select>
									</b-form-group>
								</b-col>
								<b-col lg="2" md="2" sm="2">
									<b-button class="add-assets" variant="primary" @click="addAssetType">Add</b-button>
								</b-col>
							</b-row>

							<b-row class="my-1">
								<b-col sm="12">
									<span class="sub-label" v-if="Object.keys(this.selAssetTypes).length === 0">
										There is no selected asset type yet.
									</span>
									<div v-else>
										<b-card v-for="(assetType, key) in selAssetTypes" :key="key">
											<b-row>
												<b-col sm="8">
													<span class="asset-type">{{ formAssetTypes[key].assetType }}</span>
												</b-col>
												<b-col sm="4" align="right">
													<b-button v-b-toggle="getCollapse(key)" class="m-1" variant="dark"
														size="sm" @click="toggleAssetTypeView(key)">
														<i class="fa fa-eye-slash"
															v-if="formAssetTypes[key].visible"></i>
														<i class="fa fa-eye" v-else></i>
													</b-button>
													<b-button size="sm" v-if="formAssetTypes[key].editable"
														v-b-tooltip.hover.top="'Confirm Asset Type'" variant="success"
														@click.stop="confirmAssetType(key)" class="mr-1">
														<em class="fa fa-check"></em>
													</b-button>
													<b-button size="sm" v-if="!formAssetTypes[key].editable"
														v-b-tooltip.hover.top="'Edit Asset Type'" variant="warning"
														@click.stop="editAssetType(key)" class="mr-1">
														<em class="fa fa-pencil"></em>
													</b-button>
													<b-button size="sm" v-b-tooltip.hover.top="'Delete Asset Type'"
														variant="danger" @click.stop="removeAssetType(key)"
														class="mr-1">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-col>
											</b-row>
											<b-collapse visible :id="getCollapse(key)"
												:v-model="formAssetTypes[key].visible">
												<b-row class="mt-3" no-gutters>
													<b-col lg="3" md="5" sm="5" class="mr-2">
														<b-form-group>
															<span class="label-header">Rent Frequency</span>
														</b-form-group>
													</b-col>
													<b-col lg="3" md="5" sm="5" class="mr-2">
														<b-form-group>
															<span class="label-header">Rent Rate (PHP)</span>
														</b-form-group>
													</b-col>
													<b-col lg="3" md="5" sm="5" class="mr-2">
														<b-form-group>
															<span class="label-header">Contracted Quantity</span>
														</b-form-group>
													</b-col>
												</b-row>
												<b-row no-gutters>
													<b-col lg="3" md="5" sm="5" class="mr-2">
														<b-form-group label-for="Rent Frequency">
															<span v-if="formAssetTypes[key].editable">
																<b-form-select id="rent-frequency"
																	name="Rent Frenquency"
																	v-model="formAssetTypes[key].rentFrequency"
																	v-validate="'required'"
																	:options="selAssetTypes[key].rentFrequencies"
																	:reduce="(frenquency) => frenquency.value" />

																<span v-show="errors.has('Rent Frenquency')"
																	class="help-block">
																	{{ errors.first('Rent Frenquency') }}
																</span>
															</span>
															<span v-else>
																{{ formAssetTypes[key].rentFrequency }}
															</span>
														</b-form-group>
													</b-col>
													<b-col lg="3" md="5" sm="5" class="mr-2">
														<b-form-group label-for="Rent Rate">
															<span v-if="formAssetTypes[key].editable">
																<b-form-input id="rent-rate" name="Rent Rate"
																	type="number" class="numFont" step="0.01"
																	v-model="formAssetTypes[key].rentRate"
																	v-validate="'required|decimal|min_value:0.01'"
																	:min="0.01" placeholder="Rent Rate"
																	@keydown="blockInvalidCharacters" />
																<span v-show="errors.has('Rent Rate')"
																	class="help-block">
																	{{ errors.first('Rent Rate') }}
																</span>
															</span>
															<span class="numFont" v-else>
																{{ formatMoneyValue('PHP', formAssetTypes[key].rentRate) }}
															</span>
														</b-form-group>
													</b-col>
													<b-col lg="3" md="5" sm="5" class="mr-2">
														<b-form-group label-for="Contracted Quantity">
															<span v-if="formAssetTypes[key].editable">
																<b-form-input id="contract-quantity"
																	name="Contracted Quantity" type="number"
																	class="numFont"
																	v-model="formAssetTypes[key].quantity"
																	v-validate="'required|min_value:1'" :min="1"
																	@keydown="blockInvalidCharacters" />
																<span v-show="errors.has('Contracted Quantity')"
																	class="help-block">
																	{{ errors.first('Contracted Quantity') }}
																</span>
															</span>
															<span class="numFont" v-else>
																{{ formAssetTypes[key].quantity.toLocaleString() }}
															</span>
														</b-form-group>
													</b-col>
													<b-col lg="2" md="5" sm="5">
														<b-form-group>

														</b-form-group>
													</b-col>
												</b-row>

												<!-- Additional Rates -->
												<b-row class="mt-4">
													<b-col sm="8">
														<span class="label-header">ADDITIONAL RATES</span>
													</b-col>
												</b-row>
												<b-row class="my-2" no-gutters>
													<b-col lg="6" md="10" sm="10" class="mr-2">
														<b-form-group
															description="Select the add'l classification to bill per asset type">
															<b-form-select id="additional-rates" name="addRates"
																v-model="selAssetTypes[key].condition"
																:options="selAssetTypes[key].conditions"
																:reduce="(condition) => condition.value" />
														</b-form-group>
													</b-col>
													<b-col sm="3">
														<b-button class="add-assets" variant="primary"
															@click="addBillableRates(key)">Add</b-button>
													</b-col>
												</b-row>

												<div v-if="isBillableRateNonNull(formAssetTypes[key].billableRates)">
													<b-row class="mt-2" no-gutters>
														<b-col lg="3" md="5" sm="5" class="mr-2">
															<b-form-group>
																<span class="label-header">Asset Condition</span>
															</b-form-group>
														</b-col>
														<b-col lg="3" md="5" sm="5" class="mr-2">
															<b-form-group>
																<span class="label-header">Description</span>
															</b-form-group>
														</b-col>
														<b-col lg="3" md="5" sm="5" class="mr-4">
															<b-form-group>
																<span class="label-header">VAT Exclusive Rate
																	(PHP)</span>
															</b-form-group>
														</b-col>
														<b-col lg="2" md="5" sm="5">
															<b-form-group>
																<span class="label-header">Actions</span>
															</b-form-group>
														</b-col>
													</b-row>
													<b-row
														v-for="(billingRate, id) in formAssetTypes[key].billableRates"
														:key="id" no-gutters>
														<b-col lg="3" md="5" sm="5" class="mr-2">
															<b-form-group>
																{{ formAssetTypes[key].billableRates[id].condition }}
															</b-form-group>
														</b-col>
														<b-col lg="3" md="5" sm="5" class="mr-2">
															<b-form-group>
																<span
																	v-if="formAssetTypes[key].billableRates[id].editable">
																	<b-form-input id="description" name="Description"
																		type="text"
																		v-validate="getValidationParam(true, /^(?=.*[A-Za-z])[A-Za-z\d\s(),.]+$/)"
																		v-model="formAssetTypes[key].billableRates[id].description"
																		required placeholder="Description" />
																	<span v-show="errors.has('Description')"
																		class="help-block">
																		{{ errors.first('Description') }}
																	</span>
																</span>
																<span v-else>
																	{{ getDescription(formAssetTypes, key, id) }}
																</span>
															</b-form-group>
														</b-col>
														<b-col lg="3" md="5" sm="5" class="mr-4">
															<b-form-group>
																<span
																	v-if="formAssetTypes[key].billableRates[id].editable">
																	<b-form-input id="vat-exlusive-rate"
																		name="VAT Exclusive Rate" type="number"
																		class="numFont"
																		v-model="formAssetTypes[key].billableRates[id].vatExclusiveRate"
																		v-validate="'required'"
																		@keydown="blockInvalidCharacters" />
																	<span v-show="errors.has('VAT Exclusive Rate')"
																		class="help-block">
																		{{ errors.first('VAT Exclusive Rate') }}
																	</span>
																</span>
																<span class="numFont" v-else>
																	{{ formatMoneyValue('PHP',
																		getVATExRate(formAssetTypes, key, id)) }}
																</span>
															</b-form-group>
														</b-col>
														<b-col lg="2" md="5" sm="5">
															<b-form-group>
																<b-button size="sm"
																	v-if="formAssetTypes[key].billableRates[id].editable"
																	v-b-tooltip.hover.top="'Confirm Billable Rate'"
																	variant="success"
																	@click.stop="confirmBillableRates(key, id)"
																	class="mr-1">
																	<em class="fa fa-check"></em>
																</b-button>
																<b-button size="sm"
																	v-if="!formAssetTypes[key].billableRates[id].editable"
																	v-b-tooltip.hover.top="'Edit Billable Rate'"
																	variant="warning"
																	@click.stop="editBillableRates(key, id)"
																	class="mr-1">
																	<em class="fa fa-pencil"></em>
																</b-button>
																<b-button size="sm"
																	v-b-tooltip.hover.top="'Delete Billable Rate'"
																	variant="danger"
																	@click.stop="removeBillableRates(key, id)"
																	class="mr-1">
																	<em class="fa fa-trash"></em>
																</b-button>
															</b-form-group>
														</b-col>
													</b-row>
												</div>
												<div v-else>
													<b-row class="my-2">
														<b-col sm="8">
															<span class="sub-label">No billable charges added
																yet.</span>
														</b-col>
													</b-row>
												</div>
											</b-collapse>
										</b-card>
									</div>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { ClientAccountUtil } from '@/utils/clientAccountUtil';
import { DateUtil } from '@/utils/dateutil';
import { DifferenceUtil } from '@/utils/differenceUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API & DAO
import clientAccountApi from '@/api/clientAccountApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'edit-client-account',
	components: {
		Loading,
	},
	props: {
		allAssetOwnerOptions: {
			type: Array,
			required: true,
		},
		allClientOptions: {
			type: Array,
			required: true,
		},
		allConnectionsObj: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
		allAssetTypesObj: {
			type: Object,
			required: true,
		},
		allClientAccountsObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			form: {},

			dateFormatOptions: { ...config.dateFormatOptions },
			contactMetricOptions: [...config.contractMetrics],
			contractPeriodOptions: [...config.contractPeriods],
			billingFrequencyOptions: [...config.billingFrequency],
			accountManagerOptions: [],
			clientManagerOptions: [],
			assetTypeOptions: [],
			dailyOptions: [],
			monthOptions: [...config.monthTypes],

			selAssetOwner: { ...config.companyDefaultValue },
			selClient: { ...config.companyDefaultValue },
			selPrincipal: { ...config.companyDefaultValue },
			selAccountManager: { ...config.userDefaultValue },
			selClientManager: { ...config.userDefaultValue },
			selAssetType: { ...config.assetTypeDefaultValue },

			selContractPeriod: null,
			selContractLength: 0,
			selContractMetric: null,

			selBillTimeSchedule: null,
			selBillDaySchedule: null,
			selBillMonthSchedule: null,
			selStartDate: null,

			selAssetTypes: {},
			formAssetTypes: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		selAssetOwner: function (newVal) {
			if (newVal && newVal.id) {
				let companyId = newVal.id;
				this.assetTypeOptions = DropDownItemsUtil.retrieveAssetTypesByOriginId(this.allAssetTypesObj, companyId, true);
			}
		},
		selContractPeriod: function (newVal) {
			if (newVal && newVal.includes('Month')) {
				this.billingFrequencyOptions = _.filter(this.billingFrequencyOptions, o => {
					return o.text !== 'Yearly';
				});
			} else {
				this.billingFrequencyOptions = [...config.billingFrequency];
			}
		},
		selContractLength: function (newVal) {
			if (newVal) {
				this.selContractLength = parseInt(newVal);
			}
		},
		selBillMonthSchedule: function (newVal) {
			if (newVal) {
				this.initBillMonthSchedule();
			}
		}
	},
	computed: {
		datePickerDisabled() {
			return this.form.status !== 'Inactive';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		formattedDate() {
			return moment(this.form.startDate).format('MMMM D YYYY hh:mm A');
		},
		accountNoRegex() {
			return config.accountNoRegex;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
		clientAccountDefaultObj() {
			return ClientAccountUtil.getClientAccountDefaultObj();
		},
		isOthersContractPeriodRequired() {
			return this.selContractPeriod === ' - Others - ';
		},
		isTimeOfDayRequired() {
			return this.form.billingFrequency === 'Daily';
		},
		isDayOfMonthRequired() {
			return this.form.billingFrequency === 'Monthly';
		},
		isDayOfYearRequired() {
			return this.form.billingFrequency === 'Yearly';
		}
	},
	methods: {
		blockInvalidCharacters(event) {
			// Check if the key pressed corresponds to one of the blocked characters
			if (event.key === '+' || event.key === '-' || event.key === 'e') {
				// Prevent the default behavior (typing the character)
				event.preventDefault();
			}
		},
		getVATExRate(formAssetTypes, key, id) {
			return formAssetTypes[key].billableRates[id].vatExclusiveRate;
		},
		getDescription(formAssetTypes, key, id) {
			return formAssetTypes[key].billableRates[id].description;
		},
		getValidationParam(isRequired, regex) {
			return {
				required: isRequired,
				regex: regex,
			};
		},
		getCollapse(key) {
			return 'collapse-' + key;
		},
		toggleAssetTypeView(key) {
			this.formAssetTypes[key].visible = !this.formAssetTypes[key].visible;
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				this.isLoading = false;
				return;
			}

			if (!this.validateAccount()) {
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		validateAccount() {
			// Check if existing account no
			if (ValidationUtil.objectHasField('accountNo', this.form.accountNo, this.allClientAccountsObj)
				&& this.form.accountNo !== this.$store.getters.currClientAccount.accountNo) {
				this.$toaster.warning('Account No already exists.');
				this.isLoading = false;
				return;
			}

			// Bill Schedule
			const billingSchedules = {
				'Daily': this.selBillTimeSchedule,
				'Monthly': this.selBillDaySchedule,
				'Yearly': `${this.selBillMonthSchedule} ${this.selBillDaySchedule}`
			};

			const billingSchedule = billingSchedules[this.form.billingFrequency];
			if (!this.form.billingFrequency || !billingSchedule || billingSchedule.length === 0) {
				this.$toaster.warning('Billing frequency has no schedule time');
				return false;
			}

			// Asset types
			if (_.isEmpty(this.formAssetTypes)) {
				this.$toaster.warning(`Account must have at least 1 asset type to rent`);
				return false;
			}

			let isValidAssetTypes = true;
			_.forEach(this.formAssetTypes, (value) => {
				if (!value || !value.rentFrequency) {
					this.$toaster.warning(`Asset type "${value.assetType}" is missing rent frequency`);
					isValidAssetTypes = false;
				}
				if (!value || value.quantity <= 0) {
					this.$toaster.warning(`Asset type "${value.assetType}" must have at least 1 contracted quantity`);
					isValidAssetTypes = false;
				}
				// Billable rates
				if (!_.isEmpty(value.billableRates)) {
					_.forEach(value.billableRates, billable => {
						if (!billable || billable.vatExclusiveRate <= 0) {
							this.$toaster.warning(`Asset type "${value.assetType}" contains a billable rate with zero or negative VAT Exclusive Rate`);
							isValidAssetTypes = false;
						}
					});
				}
			});

			return isValidAssetTypes;
		},

		getClientAccountObj() {
			// Asset Provider
			if (this.isSuperAdmin) {
				this.form.assetOwnerId = this.selAssetOwner.id;
				this.form.assetOwner = this.selAssetOwner.name;
			} else {
				this.form.assetOwnerId = this.loggedUserCompany.id;
				this.form.assetOwner = this.loggedUserCompany.name;
			}

			// Client
			this.form.clientId = this.selClient.id;
			this.form.client = this.selClient.name;

			// Principal
			if (this.selPrincipal && this.selPrincipal.id) {
				this.form.principalId = this.selPrincipal.id;
				this.form.principal = this.selPrincipal.name;
			}

			// Account Manager
			this.form.accountManagerId = this.selAccountManager.id;
			this.form.accountManager = this.selAccountManager.name;

			// Client Manager
			this.form.clientManagerId = this.selClientManager.id;
			this.form.clientManager = this.selClientManager.name;

			this.form.contractPeriod = this.selContractPeriod;
			if (this.selContractPeriod === ' - Others - ') {
				this.form.contractPeriod = this.selContractLength + ' ' + this.selContractMetric;
			}

			// Start Date
			this.form.startDate = DateUtil.startDateTimeStamp(new Date(this.selStartDate));

			// Ending Date
			this.form.endingDate = ClientAccountUtil.getContractEndingDate(this.form.startDate, this.form.contractPeriod);

			// Billing Schedule
			const scheduleFormats = {
				'Daily': (vm) => vm.selBillTimeSchedule,
				'Monthly': (vm) => vm.selBillDaySchedule,
				'Yearly': (vm) => `${vm.selBillMonthSchedule} ${vm.selBillDaySchedule}`
			};
			this.form.billingSchedule = scheduleFormats[this.form.billingFrequency](this);

			// Auto-compute for Total Billings
			this.form.totalBillings = ClientAccountUtil.getTotalBillings(this.form.contractPeriod, this.form.billingFrequency);

			this.form.assetTypes = Object.values(this.formAssetTypes).reduce((result, assetType) => {
				let billableRates = {};
				if (!_.isEmpty(assetType.billableRates)) {
					_.forEach(assetType.billableRates, (value, key) => {
						billableRates[key] = {
							condition: value.condition,
							description: value.description,
							vatExclusiveRate: parseFloat(value.vatExclusiveRate),
						}
					});
				}

				result[assetType.assetTypeId] = {
					assetTypeId: assetType.assetTypeId,
					assetType: assetType.assetType,
					rentFrequency: assetType.rentFrequency,
					rentRate: parseFloat(assetType.rentRate),
					rentCurrency: assetType.rentCurrency,
					quantity: parseInt(assetType.quantity),
					billableRates: billableRates,
				};

				return result;
			}, {});

			// Payment Terms
			this.form.paymentTerms = parseInt(this.form.paymentTerms);

			// Timestamp
			this.form.updatedBy = this.loggedUser.id;
			this.form.dateUpdated = DateUtil.getCurrentTimestamp();

			// Fields cleanup
			this.form = ClientAccountUtil.cleanupFields(this.form);

			return this.form;
		},
		async handleSubmit() {
			try {
				// show loading indicator
				this.isLoading = true;

				const accountObj = this.getClientAccountObj();

				if (accountObj.startDate <= DateUtil.getCurrentTimestamp() || accountObj.status === 'Active') {
					let oldAccount = this.$store.getters.currClientAccount;
					oldAccount = JSON.parse(JSON.stringify(oldAccount));
					oldAccount = ClientAccountUtil.cleanupFields(oldAccount);

					const difference = DifferenceUtil.diffClientAccounts(oldAccount, accountObj);
					if (difference.length > 0) {
						this.$refs.modal.hide();
						EventBus.$emit('onConfirmAccountUpdate', { new: accountObj, old: oldAccount });
					} else {
						await this.submit(accountObj);
					}
				} else {
					await this.submit(accountObj);
				}

			} catch (error) {
				this.$toaster.error(`An error occurred while submitting the changes to the client account. Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		async submit(accountObj) {
			let { data } = await clientAccountApi.saveClientAccount(
				accountObj,
				this.loggedUser.id,
				DateUtil.getCurrentTimestamp()
			)

			if (data.isSuccess) {
				this.$toaster.success(`Client account "${accountObj.accountNo}" was updated successfully.`);
				EventBus.$emit('onCloseSaveClientAccount', data.clientAccount);
				this.$refs.modal.hide();
			} else {
				this.$toaster.error(`Error creating client account "${accountObj.accountNo}". Please try again.`);
			}
		},

		// Asset Types related methods
		addAssetType() {
			let assetTypeObj = this.allAssetTypesObj[this.selAssetType.id];
			if (!assetTypeObj) {
				this.$toaster.warning('No asset type selected');
			}
			if (this.selAssetTypes[assetTypeObj.id]) {
				this.$toaster.warning('Asset type ' + assetTypeObj.name + ' already added on the list');
			}

			this.selAssetTypes[assetTypeObj.id] = ClientAccountUtil.getAssetTypeEntry(assetTypeObj);

			if (assetTypeObj.conditions) {
				let conditions = assetTypeObj.conditions;
				for (const condition of conditions) {
					if (condition.isBillable && condition.isActive) {
						this.selAssetTypes[assetTypeObj.id].conditions.push({
							value: condition,
							text: condition.condition
						});
					}
				}
			}

			this.$set(this.formAssetTypes, assetTypeObj.id, {
				assetTypeId: assetTypeObj.id,
				assetType: assetTypeObj.name,
				rentFrequency: assetTypeObj.rentFrequency,
				rentRate: parseFloat(assetTypeObj.rentRate),
				rentCurrency: assetTypeObj.rentCurrency,
				quantity: 0,
				billableRates: null,
				editable: true,
				visible: true
			});

			this.selAssetType = { ...config.assetTypeDefaultValue };
		},
		confirmAssetType(id) {
			let rentRate = this.formAssetTypes[id].rentRate ? this.formAssetTypes[id].rentRate : 0;
			if (rentRate <= 0) {
				this.$toaster.warning('Rent rate must be greater than 0.');
				return;
			}

			let quantity = this.formAssetTypes[id].quantity ? this.formAssetTypes[id].quantity : 0;
			if (quantity <= 0) {
				this.$toaster.warning('Contract quantity must be 1 or more.');
				return;
			}

			this.$set(this.formAssetTypes[id], 'quantity', parseInt(quantity));
			this.$set(this.formAssetTypes[id], 'rentRate', parseFloat(rentRate));
			this.$set(this.formAssetTypes[id], 'editable', false);
		},
		editAssetType(id) {
			this.$set(this.formAssetTypes[id], 'editable', true);
		},
		removeAssetType(id) {
			this.$delete(this.selAssetTypes, id);
			this.$delete(this.formAssetTypes, id);
		},

		// Billable Rates related methods
		addBillableRates(id) {
			// Check if the 'billableRates' array exists and make it reactive if not
			if (!this.formAssetTypes[id].billableRates) {
				this.$set(this.formAssetTypes[id], 'billableRates', {});
			}

			let value = this.selAssetTypes[id].condition;

			if (!value || !value.id) {
				this.$toaster.warning('No selected asset condition');
				return;
			} else if (this.formAssetTypes[id].billableRates[value.id]) {
				this.$toaster.warning('Selected asset condition is already added on the list');
				return;
			}

			// Create a new object and add it to the 'billableRates' array using $set
			this.$set(this.formAssetTypes[id].billableRates, value.id, {
				condition: value.condition,
				description: value.description,
				vatExclusiveRate: 0,
				editable: true,
			});
		},
		confirmBillableRates(id, key) {
			const billableRate = this.formAssetTypes[id].billableRates[key];
			const vatExclusiveRate = parseFloat(billableRate.vatExclusiveRate) || 0;
			const description = this.formAssetTypes[id].billableRates[key].description || "";
			if (description === "" && vatExclusiveRate === 0) {
				this.$toaster.warning('Description is required and VAT Exclusive Rate should be 1 PHP or more');
			} else if (description === "") {
				this.$toaster.warning('Description is required');
			} else if (vatExclusiveRate === 0) {
				this.$toaster.warning('VAT Exclusive Rate should be 1 PHP or more');
			} else {
				this.$set(billableRate, 'description', description);
				this.$set(billableRate, 'vatExclusiveRate', parseFloat(vatExclusiveRate));
				this.$set(billableRate, 'editable', false);
			}
		},
		editBillableRates(id, key) {
			this.$set(this.formAssetTypes[id].billableRates[key], 'editable', true);
		},
		removeBillableRates(id, key) {
			this.$delete(this.formAssetTypes[id].billableRates, key);
		},

		// UTILS
		isBillableRateNonNull(billableRates) {
			return billableRates && !_.isEmpty(billableRates);
		},

		onReset() {
			/* Reset our form values */
			this.form = { ...this.$store.getters.currClientAccount }

			this.selAssetOwner = DropDownItemsUtil.getCompanyOptionById(this.allAssetOwnerOptions, this.form.assetOwnerId);
			this.selClient = DropDownItemsUtil.getCompanyOptionById(this.allClientOptions, this.form.clientId);
			this.selPrincipal = DropDownItemsUtil.getCompanyOptionById(this.allClientOptions, this.form.principalId);

			this.initUserOptions(this.form.assetOwnerId, this.form.clientId);
			this.initBillingDetails(this.form);
			this.initAssetTypeDetails(this.form);

			this.selAssetType = { ...config.assetTypeDefaultValue };

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
		initUserOptions(ownerId, clientId) {
			this.accountManagerOptions = DropDownItemsUtil.retrieveManagersByCompany(this.allUsersObj, ownerId);
			this.clientManagerOptions = DropDownItemsUtil.retrieveActiveUsersByCompany(this.allUsersObj, clientId);

			this.selAccountManager = DropDownItemsUtil.getUserOptionById(this.accountManagerOptions, this.form.accountManagerId);
			this.selClientManager = DropDownItemsUtil.getUserOptionById(this.clientManagerOptions, this.form.clientManagerId);
		},
		initBillingDetails(clientAccount) {
			this.selContractPeriod = DropDownItemsUtil.getContractPeriodOptions(this.contractPeriodOptions, this.form.contractPeriod);
			if (this.selContractPeriod === ' - Others - ' && clientAccount.contractPeriod) {
				const arrStrings = clientAccount.contractPeriod.split(' ');
				this.selContractLength = arrStrings[0];
				this.selContractMetric = arrStrings[1];
			} else {
				this.selContractLength = 0;
				this.selContractMetric = null;
			}

			let frequency = clientAccount.billingFrequency;
			if (frequency === 'Daily') {
				this.selBillTimeSchedule = clientAccount.billingSchedule;
			} else if (frequency === 'Monthly') {
				this.initBillMonthSchedule();
				this.selBillDaySchedule = parseInt(clientAccount.billingSchedule);
			} else if (frequency === 'Yearly') {
				let [monthSchedule, daySchedule] = clientAccount.billingSchedule.split(' ');
				this.selBillMonthSchedule = monthSchedule;
				this.initBillMonthSchedule();
				this.selBillDaySchedule = parseInt(daySchedule);
			}

			this.selStartDate = new Date(clientAccount.startDate);
		},
		initBillMonthSchedule() {
			const numDays = ClientAccountUtil.getDaysInMonth(this.selBillMonthSchedule);
			this.dailyOptions = [{ value: null, text: ' - Please select - ' }];

			const getNumberPostfix = (number) => {
				if (number >= 11 && number <= 13) {
					return number + "th";
				}

				switch (number % 10) {
					case 1: return number + "st";
					case 2: return number + "nd";
					case 3: return number + "rd";
					default: return number + "th";
				}
			};

			for (let counter = 0; counter < numDays; counter++) {
				this.dailyOptions.push({ value: 1 + counter, text: getNumberPostfix(1 + counter) });
			}
		},
		initAssetTypeDetails(clientAccount) {
			let assetTypes = clientAccount.assetTypes ? clientAccount.assetTypes : {};
			if (!_.isEmpty(assetTypes)) {

				this.selAssetTypes = {};
				this.formAssetTypes = {};

				let assetsArr = Object.values(assetTypes);
				if (assetsArr && assetsArr.length > 0) {
					for (const asset of assetsArr) {
						let assetTypeObj = this.allAssetTypesObj[asset.assetTypeId];
						this.selAssetTypes[assetTypeObj.id] = {
							assetTypeId: assetTypeObj.id,
							assetType: assetTypeObj.name,
							rentFrequencies: [...config.rentFrequencyTypes],
							conditions: [{ value: null, text: ' - Please select - ' }],
							condition: null
						};

						if (assetTypeObj.conditions) {
							let conditions = assetTypeObj.conditions;
							for (const condition of conditions) {
								if (condition.isBillable) {
									this.selAssetTypes[assetTypeObj.id].conditions.push({
										value: condition,
										text: condition.condition
									});
								}
							}
						}

						this.$set(this.formAssetTypes, assetTypeObj.id, {
							assetTypeId: asset.assetTypeId,
							assetType: asset.assetType,
							rentFrequency: asset.rentFrequency,
							rentRate: parseFloat(asset.rentRate),
							rentCurrency: asset.rentCurrency,
							quantity: parseInt(asset.quantity),
							billableRates: null,
							editable: false,
							visible: true
						});

						if (!_.isEmpty(asset.billableRates)) {
							this.$set(this.formAssetTypes[asset.assetTypeId], 'billableRates', {});
							_.forEach(asset.billableRates, (value, key) => {
								this.$set(this.formAssetTypes[asset.assetTypeId].billableRates, key, {
									condition: value.condition,
									description: value.description,
									vatExclusiveRate: value.vatExclusiveRate,
									editable: false,
								});
							});
						}
					}
				}
			}
		},

		// UTILS
		formatMoneyValue(currency, number) {
			return BillingReportUtil.formatMoneyValue(currency, number);
		}
	}
};
</script>

<style scoped>
.add-assets {
	width: 100px;
	height: 36px;
}

.align-items-center {
	align-items: center !important;
}

.sub-label {
	font-style: italic;
	color: #73818f;
}

.label-header {
	font-weight: bold;
}

.field-without-label {
	margin-top: 30px;
}

.asset-type {
	font-weight: bold;
	font-size: 16px;
}
</style>
