import axios from 'axios';
import config from '@/config/env-constants';

function getProjectId() {
    if (config.currEnv === 'pallet-pooling') {
        return `${config.currEnv}`;
    } else {
        return `ayun-quantity-${config.currEnv}`;
    }
}

export default {
	baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    getClientAccounts(filterBy, view, currUserId) {
        const url = `${this.baseUrl}/getClientAccounts`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    saveClientAccount(clientAccount, currUserId, currTimeStamp) {
        const url = `${this.baseUrl}/saveClientAccount`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            clientAccount: JSON.stringify(clientAccount)
        });
    },

    saveAccountUpdateRequest(changeRequest, currUserId, currTimeStamp) {
        const url = `${this.baseUrl}/saveAccountUpdateRequest`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            changeRequest: JSON.stringify(changeRequest)
        });
    }
}