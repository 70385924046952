<template>
    <b-row class="px-2 mb-2">
        <b-col sm="12">
            <b-row>
                <b-col sm="4" class="border border-light border-1 p-1">
                    <b-row class="d-flex align-items-center">
                        <b-col sm="12" xl="4" class="px-0">
                            <div class="text-right">
                                <img v-img-orientation-changer src="img/logo-sygnet.png" class="img-logo" alt="Pallet Pooling Logo" width="85%" />
                            </div>
                        </b-col>
                        <b-col sm="12" xl="7" class="pl-2 pr-2 text-sm-center text-xl-left">
                            <div class="client-header">CLIENT ACCOUNT</div>
                            <div class="client-id"> {{ getValue(selClientAccount, 'accountNo') }} </div>
                            <div class="client-date"> {{ showFormattedDateWithTime(selClientAccount.dateCreated) }}
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="4" class="border border-light border-1 p-2">
                    <div class="details-header mb-1">PRIMARY DETAILS</div>
                    <!-- Client Name -->
                    <div class="details-field">Company: <span class="details-value">{{ getValue(selClientAccount,
                                'client') }}</span></div>
                    <!-- Principal -->
                    <div v-show="selClientAccount.principal" class="details-field">Principal: <span
                            class="details-value">{{ getValue(selClientAccount,
                                'principal') }}</span></div>
                    <!-- Account Manager -->
                    <div class="details-field">Account Manager: <span class="details-value">{{
                                getValue(selClientAccount,
                                    'accountManager') }}</span></div>
                    <!-- Client Manager -->
                    <div class="details-field">client Manager: <span class="details-value">{{ getValue(selClientAccount,
                                'client') }}</span></div>
                    <!-- Remarks -->
                    <div class="details-field">Remarks: <span class="details-value">{{
                                truncateRemarks(selClientAccount.remarks) }}</span></div>
                </b-col>
                <b-col sm="4" class="border border-light border-1 p-2">
                    <div class="details-header mb-1">BILLING DETAILS</div>
                    <!-- Asset Owner -->
                    <div class="details-field">Asset Owner: <span class="details-value">{{ getValue(selClientAccount,
                                'assetOwner') }}</span></div>
                    <!-- Contract Period -->
                    <div class="details-field">Contract Period: <span class="details-value">{{
                                getValue(selClientAccount,
                                    'contractPeriod') }}</span></div>
                    <!-- Contract Start Date -->
                    <div class="details-field">Contract Start Date: <span class="details-value">{{
                                selClientAccount.startDate ? showFormattedDate(selClientAccount.startDate) : '-' }}</span>
                    </div>
                    <!-- Billing Frequency -->
                    <div class="details-field">Billing Frequency: <span class="details-value">{{
                                getBillingFrequency(selClientAccount) }}</span></div>
                    <!-- Status -->
                    <div class="details-field">Status:
                        <span class="details-value" v-if="selClientAccount.status === 'Inactive'">
                            <b-badge variant="secondary">{{ selClientAccount.status }}</b-badge>
                        </span>
                        <span class="details-value" v-if="selClientAccount.status === 'Active'">
                            <b-badge variant="success">{{ selClientAccount.status }}</b-badge>
                        </span>
                        <span class="details-value" v-if="selClientAccount.status === 'Terminated'">
                            <b-badge variant="danger">{{ selClientAccount.status }}</b-badge>
                        </span>
                        <span class="details-value" v-if="selClientAccount.status === 'Expired'">
                            <b-badge variant="primary">{{ selClientAccount.status }}</b-badge>
                        </span>
                    </div>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ClientAccountUtil } from '@/utils/clientAccountUtil';

export default {
    name: 'client-account-summary-header',
    props: {
        selClientAccount: {
            type: Object,
            required: true
        }
    },
    methods: {
        showFormattedDate(date) {
            return DateUtil.getFormattedDate(date);
        },
        showFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getBillingFrequency(item) {
            let clientAccount = item ? item : {};
            let frequency = clientAccount.billingFrequency ? clientAccount.billingFrequency : '-';
            let schedule = clientAccount.billingSchedule ? clientAccount.billingSchedule : '-';
            return ClientAccountUtil.getBillingFrequency(frequency, schedule);
        },
        getValue(item, fieldName) {
            let clientAccount = item ? item : {};

            return clientAccount[fieldName] ? clientAccount[fieldName] : '-';
        },

        // UTILS
        truncateRemarks(string) {
            if (string.length > 30) {
                let remarks = string.substring(0, 30) + '...';
                return remarks;
            }
            return string;
        },
    }
}
</script>

<style scoped>
.img-logo {
    margin-top: 5wh;
    margin-left: 10wh;
    width: 85wh;
}

.client-header {
    text-transform: uppercase;
    color: #024F98;
    font-weight: bold;
    font-size: 1.6em;
    line-height: normal;
    margin: 0 auto;
    margin-top: -6px;
}

.client-id {
    text-transform: uppercase;
    color: #008036;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.client-date {
    color: #484554;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 1.5em;
    align-items: center;
}

.details-header {
    text-transform: uppercase;
    color: #024F98;
    font-size: medium;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.details-field {
    font-size: small;
    text-align: left;
    text-transform: uppercase;
    color: #4A4A4A;
    font-weight: 400;
    line-height: normal;
}

.details-value {
    font-size: small;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: #484554;
    font-weight: 700;
    line-height: normal;
}
.client-id {
    white-space: nowrap;    
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
</style>