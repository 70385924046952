<template>
    <b-modal v-model="show" id="request-approval-dialog" ref="request-approval-dialog" size="lg" @cancel="onReset"
        :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
        <template #modal-header>
            <div class="modal-title">
                <i v-if="action === 'Approve'" class="fa fa-check"></i>
                <i v-else class="fa fa-close"></i>
                <span>&nbsp;&nbsp;{{ modalTitle }}</span>
            </div>
        </template>

        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-container fluid>
            <b-row class="mb-3">
                <b-col sm="12">
                    <div class="modal-header-txt">{{ clientName }} - {{ accountNo }}</div>
                    <div v-html="getInstructionsTxt(action)"></div>
                </b-col>
            </b-row>

            <b-table show-empty striped hover :items="items" :fields="fields">
                <template v-slot:cell(from)="row">
                    <span v-html="row.item.from"></span>
                </template>
                <template v-slot:cell(to)="row">
                    <span v-html="row.item.to"></span>
                </template>
            </b-table>

            <b-row class="mb-2">
                <b-col sm="12">
                    <strong>Total: {{ totalRows }}</strong>
                </b-col>
            </b-row>

            <b-row class="mt-2 mb-4">
                <b-col sm="12">
                    <strong>Reason for Update:</strong>
                    <span class="update-reason">{{ request.requestReason }}</span>
                </b-col>
            </b-row>

            <!-- Confirm Changes Section -->
            <hr />
            <div class="mt-4" v-if="action === 'Approve'">
                <b-row>
                    <b-col sm="12">
                        Please input your password to approve this change request.
                    </b-col>
                    <b-col lg="7" md="9" sm="12">
                        <ConfirmPassword :form="form" />
                    </b-col>
                </b-row>
            </div>

            <b-row>
                <b-col sm="12">
                    <span class="info" v-html="getConfirmApproveTxt(action)"></span>
                </b-col>
            </b-row>
        </b-container>

        <template #modal-footer>
            <div class="w-100">
                <span class="float-right">
                    <b-button variant="secondary" @click="show = false" class="footer-button">
                        Cancel
                    </b-button>
                    <b-button :variant="action === 'Approve' ? 'primary' : 'danger'" @click="handleOk"
                        class="footer-button">
                        {{ modalOkTitle }}
                    </b-button>
                </span>
            </div>
        </template>
    </b-modal>
</template>


<script>
// Components
import ConfirmPassword from '@/views/commons/ConfirmPassword';

// Utils
import { DateUtil } from '@/utils/dateutil';
import { DifferenceUtil } from '@/utils/differenceUtil';

// API
import clientAccountApi from '@/api/clientAccountApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { firebase } from '@/config/firebase';

export default {
    name: 'confirm-account-change-request',
    components: {
        ConfirmPassword,
        Loading,
    },
    data() {
        return {
            show: false,
            items: [],
            fields: [
                {
                    key: 'index',
                    label: '#'
                },
                'fieldUpdate',
                'from',
                'to',
            ],

            action: '',
            request: {},

            form: {
                password: '',
                confirmPassword: '',
            },

            modalTitle: 'Confirm Account Update',
            modalOkTitle: 'Save',

            totalRows: 0,

            loggedUser: this.$store.getters.loggedUser,

            // Check for loader
            isLoading: false,
        };
    },
    computed: {
        disableConfirmButtons() {
            return this.isLoading;
        },
        passwordRegex() {
            return config.passwordRegex;
        },
        clientName() {
            let request = this.request ? this.request : {};
            return request.old && request.old.client ? request.old.client : '-';
        },
        accountNo() {
            let request = this.request ? this.request : {};
            return request.old && request.old.accountNo ? request.old.accountNo : '-';
        }
    },
    mounted() {
        EventBus.$on('onConfirmAccountChangeRequest', (param) => {
            this.$bvModal.show('request-approval-dialog');
            this.initRequestDialog(param);
        });
    },
    methods: {
        getInstructionsTxt(action) {
            return action === 'Approve' ? 'Please review the following details for your approval:'
                : 'You are about to reject the following change request details for this account: ';
        },
        getConfirmApproveTxt(action) {
            return action === 'Approve' ? '<i class="fa fa-info-circle" aria-hidden="true"></i> By clicking <strong>Approve</strong>, all details from this change request will reflect on the client account.'
                : '<i class="fa fa-info-circle" aria-hidden="true"></i> By clicking <strong>Reject</strong>, all details from this change request will not reflect on the client account.';
        },

        initRequestDialog(param) {
            this.request = param.request;
            this.action = param.action;
            this.modalTitle = param.action === 'Approve' ?
                'Approve Change Request' :
                'Reject Change Request';
            this.modalOkTitle = param.action === 'Approve' ?
                'Approve' : 'Reject';

            this.items = DifferenceUtil.diffClientAccounts(this.request.old, this.request.new);
            this.totalRows = this.items.length;
        },

        async handleOk(evt) {
            evt.preventDefault();
            this.isLoading = true;

            const isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                this.isLoading = false;
                return;
            }

            if (this.action === 'Approve') {
                this.checkPasswordMatch();
            } else {
                await this.handleReject();
            }
        },

        checkPasswordMatch() {
            if (this.form.password === this.form.confirmPassword) {
                this.authenticateAndSubmit();
            } else {
                this.$toaster.warning('The passwords you provided do not match. Please ensure that both password entries are identical.');
                this.isLoading = false;
            }
        },

        authenticateAndSubmit() {
            const credentials = firebase.auth.EmailAuthProvider.credential(this.loggedUser.id, this.form.password);
            const user = firebase.auth().currentUser;
            user.reauthenticateWithCredential(credentials)
                .then(async () => {
                    await this.handleSubmit();
                })
                .catch(() => {
                    this.$toaster.warning('Password does not match. Please try again.');
                    this.isLoading = false;
                });
        },

        async handleSubmit() {
            this.isLoading = true;

            this.request.status = 'Approved';
            this.request.approvedBy = this.loggedUser.id;
            this.request.dateApproved = DateUtil.getCurrentTimestamp();

            let account = this.request.new;

            try {
                let { data } = await clientAccountApi.saveAccountUpdateRequest(
                    this.request,
                    this.loggedUser.id,
                    DateUtil.getCurrentTimestamp()
                );

                if (data.isSuccess) {
                    this.$toaster.success(`Client account "${account.accountNo}" was updated successfully.`);
                    EventBus.$emit('onCloseSaveClientAccount', data.changeRequest.new);
                    this.$refs['request-approval-dialog'].hide();
                    this.onReset();
                } else {
                    this.$toaster.error(`Error updating client account "${this.request.new.accountNo}". Please try again.`);
                }
            } catch (error) {
                this.$toaster.error(`There was an error submitting update request for client account no. ${account.accountNo}. Please try again later.`);
            } finally {
                this.isLoading = false;
            }
        },

        async handleReject() {
            try {
                this.isLoading = true;

                this.request.status = 'Rejected';
                this.request.rejectedBy = this.loggedUser.id;
                this.request.dateRejected = DateUtil.getCurrentTimestamp();

                let { data } = await clientAccountApi.saveAccountUpdateRequest(
                    this.request,
                    this.loggedUser.id,
                    DateUtil.getCurrentTimestamp()
                );

                if (data.isSuccess) {
                    this.$toaster.success(`Change request for account "${this.request.new.accountNo}" was successfully rejected.`);
                    EventBus.$emit('onCloseSaveClientAccount', this.request.old);
                    this.$refs['request-approval-dialog'].hide();
                    this.onReset();
                } else {
                    this.$toaster.error(`Error updating change request with account number "${this.request.new.accountNo}". Please try again.`);
                }
            } catch (error) {
                this.$toaster.error(`There was an error submitting update request for client account no. ${this.request.new.accountNo}. Please try again later.`);
            } finally {
                this.isLoading = false;
            }
        },

        onReset() {
            this.isLoading = false;
            this.items = [];

            this.action = '';
            this.request = {};

            this.form.password = '';
            this.form.confirmPassword = '';

            this.modalTitle = '';
            this.modalOkTitle = '';
            this.show = true;
        },
    },
    beforeDestroy() {
        EventBus.$off('onConfirmAccountChangeRequest');
    },
};
</script>

<style scoped>
.update-reason {
    margin-left: 10px;
    font-style: italic;
    font-weight: bold;
    color: #024F98 !important;
}

.footer-button {
    margin-left: 5px;
}
</style>