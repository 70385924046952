<template>
    <b-card-text>
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
        <b-col sm="12" class="my-1 mt-4">

            <!-- Select Actions and Items Per Page Options -->
            <b-row>
                <b-col sm="6" md="6">
                    <span class="details-view-title">Change Logs</span>
                    <div class="details-view-subtitle">List of change logs on this account</div>
                </b-col>
                <b-col sm="6" md="4" offset-md="2" class="mt-2 text-md-right">
                    <b-input-group prepend="Show" append="/ Page">
                        <b-form-select :options="pageOptions" v-model="perPage" />
                    </b-input-group>
                </b-col>
            </b-row>

            <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
                :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" responsive>

                <template v-slot:cell(dateCreated)="row">
                    {{ getFormattedDateWithTime(row.item.dateCreated) }}
                </template>

                <template v-slot:cell(changes)="row">
                    <span v-html="getFormattedChanges(row.item.changes)"></span>
                </template>

                <template v-slot:cell(reason)="row">
                    <i> {{ row.item.reason ? row.item.reason : '-' }}</i>
                </template>
            </b-table>

            <b-row>
                <b-col md="8" sm="12" class="my-1">
                    <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                </b-col>
                <b-col md="4" sm="12" class="my-1">
                    <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                        class="my-0" />
                </b-col>
            </b-row>

        </b-col>
    </b-card-text>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';
import { DifferenceUtil } from '@/utils/differenceUtil';

// DAO
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'client-account-change-logs-details-view',
    components: {
        Loading
    },
    props: {
        row: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'dateCreated',
                    sortable: true,
                },
                'changes',
                'reason',
                'updatedBy',
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            clientAccountId: '',

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            isManager: this.$store.getters.isManager,
            loggedUser: this.$store.getters.loggedUser,
            // Check for loader
            isLoading: false,
        }
    },
    watch: {
        clientAccountId: function (newVal) {
            if (newVal && newVal.length > 0) {
                this.retrieveChangeLog(newVal);
            }
        },
    },
    async mounted() {
        this.clientAccountId = this.row.item.id;
        await this.retrieveChangeLog(this.clientAccountId);
    },
    methods: {
        async retrieveChangeLog(accountId) {
            try {
                // show loading indicator
                this.isLoading = true;

                this.items = [];

                let param = {
                    collection: 'clientAccounts',
                    id: accountId,
                    userId: !this.isManager && !this.isSuperAdmin ? this.loggedUser.id : ''
                }

                let result = await auditTrailLogsDAO.getAuditTrailLogs(param);
                let changeLogs = _.filter(result, o => {
                    return o.old !== null;
                });

                _.forEach(changeLogs, changeLog => {
                    const changes = DifferenceUtil.diffClientAccounts(changeLog.old, changeLog.new);
                    if (changes.length > 0) {
                        this.items.push({
                            dateCreated: changeLog.dateCreated,
                            changes: changes,
                            reason: this.getReason(changeLog),
                            updatedBy: changeLog.new.updatedBy
                        });
                    }
                });

                this.items = _.uniqBy(this.items, 'dateCreated');
                this.items = _.sortBy(this.items, ['dateCreated']);
                this.items.reverse();
                this.totalRows = _.size(this.items);

            } catch (error) {
                this.$toaster.error('An error occurred while attempting to retrieve the list of change logs.');
            }

            // hide loading indicator
            this.isLoading = false;
        },

        getReason(changeLog) {
            if (changeLog.new.status === 'Active') {
                return changeLog.new.activateReason;
            } else if (changeLog.new.status === 'Terminated') {
                return changeLog.new.terminationReason;
            }
            return '-';
        },
        getFormattedChanges(changes) {
            let formattedHtml = '';
            if (changes && changes.length > 0) {
                for (const change of changes) {
                    if (change.fieldUpdate !== 'Billable Rates' && change.fieldUpdate !== 'Asset Types') {
                        formattedHtml += `
					<span>
					<strong>${change.fieldUpdate}</strong><br/>
					From <strong style="color: #E93F19">${change.from}</strong> 
					To <strong style="color: #3F8120">${change.to}</strong>
					</span><br/><br/>
				`;
                    } else {
                        formattedHtml += `
					<span>
					<strong>${change.fieldUpdate}</strong><br/>
                    <span>${change.to}</span>
					</span><br/><br/>
				`;
                    }
                }
            }
            return formattedHtml;
        },
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
    }
}
</script>